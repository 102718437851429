import React from "react";

function App() {
  return (
    <div className="App">
      <div>
        <img src="/img/logo_beeldmerk_2.png" alt="Kleine Koning" />
      </div>
      <div>
        <img src="/img/logo_kleine.png" alt="Kleine" />
        <img src="/img/logo_koning.png" alt="Kleine" />
      </div>
      <h1>Wij zijn per 13-12-2021 gesloten</h1>
      <h2>Bedankt voor alle support!</h2>
      <div><a href="mailto:contact@kleinekoning.nl">contact@kleinekoning.nl</a></div>
    </div>
  );
}

export default App;
